@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  src: url("Roboto-Thin.141eb1d9.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url("Roboto-Light.fa5a1399.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url("Roboto-Regular.6958d21f.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url("Roboto-Medium.396fc0cf.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url("Roboto-Bold.bf0b68fe.ttf") format("truetype");
}

/*# sourceMappingURL=index.8c88aa7c.css.map */
